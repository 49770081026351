import React from 'react'

import './head.css'

const Head = () => (
  <div id="head">
    <h1>talkTimer</h1>
    <h3>The best timer for your presentation.</h3>
  </div>
)

export default Head
